/* DO NOT EDIT OUTSIDE OF MASTER */

import moment from 'moment'

export const state = () => ({
  client: 'web',
  env: null,
  loading: false,
  errors: [],
  location: null,
  locationList: null,
  allLocations: [],
  defaultMenu: null,
  selectedCategory: null,
  menu: null,
  order: null,
  checkoutStep: 0,
  showCart: false,
  user: null,
  token: null,
  orderHistory: null,
  redirect: null,
  presubmitting: false,
  submitting: false,
  navMenus: {},
  options: {},
  icons: {},
  showNav: false,
  savedPayments: false,
  instorePayment: false,
  accounts: [],
  selectedAccount: null,
  supports: null,
  giftcards: [], // User manually entered gift cards
  savedGiftcards: [], // All saved giftcards that return after order validation,
  selectedGiftcards: [], // User selected giftcards to use for payment
  selectedSavedGiftcards: [], // User selected saved giftcards to use for payment
  banner: {},
  superCategories: {},
  timePicker: false,
  locationFeatured: {},
  menuFeatured: {},
  sitePopup: {},
  showSitePopup: false
})

export const mutations = {
  setClient(state, str) {
    state.client = str
  },

  setEnv(state, str) {
    state.env = str
  },

  setLoading(state, bool) {
    state.loading = bool
  },

  setErrors(state, arr) {
    state.errors = arr
  },

  setLocation(state, obj) {
    if (obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_loc`)
    } else {
      obj.expires = moment().add(7, 'days').format('LLLL')
      state.location = obj
      localStorage.setItem(`${state.options.appPrefix}_loc`, JSON.stringify(obj))
      return
    }

    state.location = obj
  },

  setLocationList(state, something) {
    state.locationList = something
  },

  setAllLocations(state, arr) {
    state.allLocations = arr
  },

  setDefaultMenu(state, obj) {
    state.defaultMenu = obj
  },

  setSelectedCategory(state, str) {
    state.selectedCategory = str
  },

  setSuperCategories(state, obj) {
    state.superCategories = obj
  },

  setLocationFeatured(state, obj) {
    state.locationFeatured = obj
  },

  setMenuFeatured(state, obj) {
    state.menuFeatured = obj
  },

  setMenu(state, obj) {
    if (obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_menu`)
    } else {
      obj.expires = moment().add(1, 'days').format('LLLL')
      localStorage.setItem(`${state.options.appPrefix}_menu`, JSON.stringify(obj))
    }
    state.menu = obj
  },

  setOrder(state, obj) {
    if (obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_order`)
    } else {
      obj.expires = moment().add(1, 'days').format('LLLL')
      localStorage.setItem(`${state.options.appPrefix}_order`, JSON.stringify(obj))
    }
    state.order = obj
  },

  setCheckoutStep(state, str) {
    state.checkoutStep = str
  },

  setTimePicker(state, bool) {
    state.timePicker = bool
  },

  setHandoff(state, str) {
    state.order.handoff = str
  },

  setPreSubmitting(state, bool) {
    state.presubmitting = bool
  },

  setSubmitting(state, bool) {
    state.submitting = bool
  },

  setShowCart(state, bool) {
    state.showCart = bool
  },

  setUser(state, obj) {
    if (obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_user`)
    } else {
      localStorage.setItem(`${state.options.appPrefix}_user`, JSON.stringify(obj))
    }
    state.user = obj
  },

  setToken(state, obj) {
    if (obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_tok`)
    } else {
      localStorage.setItem(`${state.options.appPrefix}_tok`, JSON.stringify(obj))
    }
    state.token = obj
  },

  setOrderHistory(state, arr) {
    state.orderHistory = arr
  },

  setRedirect(state, str) {
    state.redirect = str
  },

  setNavMenus(state, obj) {
    state.navMenus = obj
  },

  setBanner(state, obj) {
    state.banner = obj
  },

  setOptions(state, obj) {
    state.options = obj
  },

  setIcons(state, obj) {
    state.icons = obj
  },

  setShowNav(state, bool) {
    state.showNav = bool
  },

  setSavedPayments(state, bool) {
    state.savedPayments = bool
  },

  setInstorePayment(state, bool) {
    state.instorePayment = bool
  },

  setAccounts(state, arr) {
    state.accounts = arr
  },

  setSelectedAccount(state, str) {
    state.selectedAccount = str
  },

  setSupports(state, obj) {
    state.supports = obj
  },

  setGiftcards(state, arr) {
    state.giftcards = arr
  },

  setSavedGiftcards(state, arr) {
    state.savedGiftcards = arr
  },

  setSelectedGiftcards(state, arr) {
    state.selectedGiftcards = arr
  },

  setSelectedSavedGiftcards(state, arr) {
    state.selectedSavedGiftcards = arr
  },

  setSitePopup(state, obj) {
    state.sitePopup = obj
  },
  setShowSitePopup(state, obj) {
    state.showSitePopup = obj
  }
}

const setGlobals = async ({ context, commit }) => {
  let globals = await context.$storyblok.getStories('_globals')
  let menus = {}
  globals.map(g => {
    if (g.content.component == 'menu') {
      menus[g.slug] = g.content.links
    } else if (g.content.component == 'company-menu') {
      menus[g.slug] = g.content.links
    } else if (g.content.component == 'quick-links-menu') {
      menus[g.slug] = g.content.links
    } else if (g.content.component == 'global') {
      let options = {}
      g.content.options.map(o => {
        options[o.key] = o.value == 'yes' ? true : o.value == 'no' ? false : o.value
      })
      commit('setOptions', options)
    } else if (g.content.component == 'icons') {
      let icons = {
        logo: g.content.logo
      }
      commit('setIcons', icons)
    } else if (g.content.component == 'banner') {
      commit('setBanner', g.content)
    } else if (g.content.component == 'location_list') {
      g.content.locations.sort(function (a, b) { // alphabetize by city
        var keyA = a.city,
          keyB = b.city
        if (keyA < keyB) return 1
        if (keyA > keyB) return -1
        return 0
      })
      commit('setAllLocations', g.content.locations)
    } else if (g.content.component == 'featured-section') {
      if (g.content._uid == '5d12761a-5ff8-4c4c-9eee-1049fa961b72') {
        commit('setMenuFeatured', g.content)
      }
      if (g.content._uid == '26a8804a-6de9-4ac5-92c6-f3a5767debc5') {
        commit('setLocationFeatured', g.content)
      }
    } else if (g.content.component == 'site-popup') {
      commit('setSitePopup', g.content)
    }
  })
  commit('setNavMenus', menus)
  return true
}

export const actions = {
  async nuxtServerInit({ commit }, context) {
    await setGlobals({ context, commit })
  },
  async nuxtClientInit({ commit }, context) {
    const p = this.state.options.appPrefix || 'och'
    if (localStorage.getItem(`${p}_loc`)) {
      const isLocationExpired = moment().isAfter(JSON.parse(localStorage.getItem(`${p}_loc`)).expires)

      if (isLocationExpired) {
        localStorage.removeItem(`${p}_loc`)
      }

      commit('setLocation', JSON.parse(localStorage.getItem(`${p}_loc`)))
    }
    if (localStorage.getItem(`${p}_menu`)) {
      commit('setMenu', JSON.parse(localStorage.getItem(`${p}_menu`)))
    }
    if (localStorage.getItem(`${p}_order`)) {
      const isOrderExpired = moment().isAfter(JSON.parse(localStorage.getItem(`${p}_order`)).expires)

      if (isOrderExpired) {
        localStorage.removeItem(`${p}_order`)
      }

      commit('setOrder', JSON.parse(localStorage.getItem(`${p}_order`)))
    }
    if (localStorage.getItem(`${p}_user`)) {
      commit('setUser', JSON.parse(localStorage.getItem(`${p}_user`)))
      commit('customer/setCustomerType', 'user')
    }
    if (localStorage.getItem(`${p}_tok`)) {
      commit('setToken', JSON.parse(localStorage.getItem(`${p}_tok`)))
    }

    if (process.env.NODE_ENV != 'development' && process.env.STORYBLOK_PREVIEW == 'true') {
      await setGlobals({ context, commit })
    }
  },
  async locationSearch({ }, data) {
    return await this.$api.findLocations(data)
  },
  async accountSignin({ commit }, data) {
    const tokenV2 = await this.$apiv2.loginUserV2(data)

    if (tokenV2) {
      const token = await this.$api.loginSSO({
        provider: tokenV2.provider,
        token: tokenV2.value
      })

      if (token) {
        const user = await this.$api.getUser()
        if (user) {
          commit('customer/setCustomerType', 'user')
          if (this.state.order) {
            this.$router.push('/order/checkout')
          } else {
            this.$router.push('/account')
          }
        }
      }
      // else {
      //   commit('setErrors', ['O No! Incorrect email and/or password. Check your credentials and try again, or contact us if you have an issue.'])
      // }
    }
    // else {
    //   commit('setErrors', ['O No! Incorrect email and/or password. Check your credentials and try again, or contact us if you have an issue.'])
    // }
  },
  async accountSignup({ commit }, data) {
    const currentDate = new Date()
    data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
    data.dob = data.dob.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$1-$2')
    data.anniversary = moment(currentDate).format('YYYY-MM-DD')

    const tokenV2 = await await this.$apiv2.createUserV2(data)

    if (tokenV2?.value) {
      const token = await this.$api.loginSSO({
        provider: tokenV2.provider,
        token: tokenV2.value
      })

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'sign_up',
        method: 'email',
      });

      if (token) {
        const user = await this.$api.getUser()

        if (user) {
          commit('customer/setCustomerType', 'user')
          if (this.state.order) {
            this.$router.push('/order/checkout')
          } else {
            this.$router.push('/account')
          }
        }
      }
    }
  },
  async accountUpdate({ commit }, data) {
    if (data.password == '') {
      delete data.password
    }
    if (data.password && data.password.length < 8) {
      commit('setErrors', ['Password must be at least 8 characters long.'])
    } else {
      data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
      data.dob = data.dob.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$1-$2')
      // if(await this.$api.evaluateToken()) {
      const update = await this.$api.updateUser(data)
      if (update) {
        if (update.tokens) {
          commit('setToken', update.tokens)
        }
        this.$router.push('/account')
      }
      // }
    }
  },
  async accountSignout({ commit }) {
    await this.$api.logoutUser()
    commit('customer/setCustomerType', null)
    commit('setSavedGiftcards', [])
    commit('setSelectedSavedGiftcards', [])
    this.$router.push(this.state.options.signinPage)
  },
  async forgotPassword({ }, data) {
    await this.$api.forgotPassword(data.email)
    return true
  },
  fanFave({ }, fave) {
    return this.state.options.fanFaves.split(',').indexOf(fave) > -1
  },
  updateCustomer({ commit }, data) {
    commit('customer/setCustomer', data)
  },
  updateDelivery({ commit }, data) {
    commit('customer/setDeliveryAddress', data)
  },
  updatePayment({ commit }, data) {
    commit('customer/setPayment', data)
  },
  updateRoute({ }, data) {
    this.$router.push(data)
  }
}

export const getters = {
  savedGiftcardsFiltered: (state) => {
    // Check if giftcards is not an array or is empty
    if (!Array.isArray(state.savedGiftcards) || state.savedGiftcards.length === 0) {
      return [];
    }

    // Filter and remove duplicates based on card titles and non-zero balances
    const uniqueGiftCards = state.savedGiftcards.filter((giftCard, index, array) => {
      return (
        array.findIndex((c) => c.card.title === giftCard.card.title) === index &&
        giftCard.balance.balance
      );
    });

    return uniqueGiftCards;
  },
  availableGiftcards: (state, getters) => {
    const justAddedGiftcards = state.giftcards?.filter(g => g.balance > 0) || []
    const savedGiftcards = getters.savedGiftcardsFiltered || []

    return [...justAddedGiftcards, ...savedGiftcards]
  },
  giftcardCoversTotal: (state) => {
    if (!state.order?.totals.total) {
      return false
    }

    const appliedGiftCards = [...(state.selectedGiftcards || []), ...(state.selectedSavedGiftcards || [])]
    const giftcardsTotal = appliedGiftCards.reduce((acc, card) => acc + card.balance, 0)

    return giftcardsTotal >= state.order.totals.total
  },
}
